<template>
    <div id="state-asset-cluster-component">
        <v-card class="pa-2 elevation-0">
            <v-card-text>
                <v-row class="font-16">
                    <v-col class="py-0">
                        <span class="font-color-medium">Volume en kg</span>
                        <p class="pt-1 mb-0">
                           <span class="font-weight-bold">
                               {{ numberWithSpaces(asset_cluster.volume_actual) }}
                           </span>
                            sur
                            <span class="font-weight-bold">
                               {{ numberWithSpaces(asset_cluster.volume_total) }}
                           </span>
                        </p>
                    </v-col>

                    <v-divider color="white" vertical/>

                    <v-col class="py-0">
                        <span class="font-color-medium">Consommation (Kg/j)</span>
                        <p class="pt-1 mb-0 font-weight-bold">
                            {{ numberWithSpaces(asset_cluster.consumption) }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'StateAssetClusterComponent',
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
        asset_cluster: {
            type: Object,
            required: true,
            default: function () {
                return { volume_actual: null, volume_total: null, consumption: null }
            },
        },
    }
}
</script>
