<template>
    <div id="detail-asset-cluster-page">
        <toolbar-back-button-component :title="asset_cluster.name" icon_subtitle="mdi-map-marker-outline"/>

        <div class="pa-2">
            <v-row class="ma-0">
                <v-col class="pb-0" cols="12" lg="6">
                    <v-card class="elevation-0">
                        <v-card-text align="center" justify="center" class="font-16">
                            <p class="mb-2 font-color-medium">Nombre de silos composant le bâtiment</p>
                            <span class="font-weight-bold">{{ asset_cluster.assets.length }}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" lg="6" class="px-6">
                    <before-break-consumption-component :is_open="true" :asset_cluster="asset_cluster"/>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" lg="6" class="px-6">
                    <state-asset-cluster-component :asset_cluster="asset_cluster"/>
                </v-col>
            </v-row>
        </div>

        <div class="px-6 pt-4">
            <v-divider class="my-2" />

            <p class="font-weight-bold font-color-medium font-20">
                SILOS DU BÂTIMENT
            </p>

            <v-row>
                <v-col v-for="asset in asset_cluster.assets" :key="asset.id_asset" class="mt-2" cols="12" lg="6">
                    <silo-card-overview-component :silo="asset" :display_detail_button="true"/>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import BeforeBreakConsumptionComponent from "../../components/Widget/AssetCluster/BeforeBreakConsumptionComponent";
import SiloCardOverviewComponent from "../../components/Equipment/Card/SiloCardOverviewComponent";
import StateAssetClusterComponent from "../../components/Widget/AssetCluster/StateAssetClusterComponent";
import ToolbarBackButtonComponent from "../../components/Global/ToolbarBackButtonComponent";

export default {
    name: "DetailAssetClusterPage",
    components: {
        BeforeBreakConsumptionComponent, SiloCardOverviewComponent, StateAssetClusterComponent, ToolbarBackButtonComponent
    },
    data() {
        return {
            asset_cluster: this.$store.getters['asset_cluster/getAssetClusterById'](this.$route.params.id)
        }
    }
}
</script>
