<template>
    <div id="before-break-consumption-component">
        <v-card class="elevation-0">
            <v-row class="pa-4">
                <chart-before-break-consumption-component :asset_cluster="asset_cluster"/>
            </v-row>


            <v-row class="px-4 pb-4 mt-0 font-16">
                <v-col class="py-0">
                    <span class="font-color-medium">Jours avant 27 T</span>
                    <p class="pt-1 mb-0 font-weight-bold">
                        {{ asset_cluster.nb_day_before_27t }}
                    </p>
                </v-col>

                <v-divider color="white" vertical/>

                <v-col class="py-0">
                    <span class="font-color-medium">Jours avant rupture</span>
                    <p class="pt-1 mb-0 font-weight-bold">
                        {{ asset_cluster.nb_day_before_failure }}
                    </p>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ChartBeforeBreakConsumptionComponent from "./ChartBeforeBreakConsumptionComponent";

export default {
    name: "BeforeBreakConsumptionComponent",
    components: {ChartBeforeBreakConsumptionComponent },
    props: {
        asset_cluster: {
            type: Object,
            required: true,
            default: function () {
                return { nb_day_before_27t: null, nb_day_before_failure: null }
            },
        },
    }
}
</script>